import './styles/booking.css';
import LoadingIndicator from './loadingIndicator/loadingIndicator';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Datetime from "react-datetime";

function Booking() {
  const { register, watch, handleSubmit, formState: { errors } } = useForm();
  const bookingType = watch("bookingType", "performance");
  const otherTitle = watch("title");
  const [showFields, setShowFields] = useState(true);
  const [showOther, setShowOther] = useState(false);
  const [dateAndTime, setStartDate] = useState(new Date());
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showThank, setThankYou] = useState(false);

  const onSubmit = (data, e) => {
    if (valid) {
      setLoading(true);
      const bookingData = JSON.stringify({ ...data, dateAndTime });
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.onload = function () {
        setLoading(false);
        setThankYou(true);
        e.target.reset();
        console.log("response::", this.responseText);
      };
      xmlhttp.open("POST", "/mail/index.php?booking=" + bookingData);
      xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      xmlhttp.send();
    }
  }

  useEffect(() => {
    if (showFields) {
      if (dateAndTime && Object.keys(errors).length === 0) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
    else if (Object.keys(errors).length === 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [errors, dateAndTime]);

  useEffect(() => {
    setShowFields(bookingType === "performance")
  }, [bookingType])

  useEffect(() => {
    setShowOther(otherTitle === "Other")
  }, [otherTitle])


  return (<>
    <LoadingIndicator show={loading} />
    <header className="py-3 bg-white filter drop-shadow-lg">
      <img src="logo.gif" alt="Ndlela Music Logo" className="h-16 mx-auto" />
    </header>
    <main className="w-screen main py-10 md:py-20 min-h-screen">
      <div className="w-10/12 md:w-9/12 mx-auto">
        <h1 className="text-2xl sm:text-5xl leading-none font-bold text-white mb-8 uppercase text-center">
          Bookings
        </h1>
      </div>
      {showThank && <section className="w-11/12 md:w-7/12 mx-auto rounded-md bg-white bg-opacity-70 p-5 md:p-10">
        <h2 className="text-center text-2xl sm:text-4xl font-bold">Thank you</h2>
        <p className="text-center my-5 md:my-10">We respect your privacy and will get back to you shortly.</p>
        <button className="w-full md:w-auto md:mx-auto md:block shadow bg-yellow-400 hover:bg-yellow-200 focus:shadow-outline focus:outline-none text-black font-bold py-3 px-10 rounded" onClick={() => { setThankYou(false) }}>
          Okay
        </button>
      </section>}
      {!showThank && <section className="w-11/12 md:w-7/12 mx-auto rounded-md bg-black bg-opacity-70 p-5 md:p-10">
        <form className="w-full max-w-lg mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="artist">
                Artist
              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative">
                <select {...register("artist", { required: true })} className="tn-input" id="artist">
                  <option value="Thandi Ntuli">Thandi Ntuli</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="bookingType">
                Performance or Composition
              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative">
                <select {...register("bookingType", { required: true })} className="tn-input" id="bookingType">
                  <option value="performance">Performance</option>
                  <option value="composition">Composition</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="performance">

              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative">
                {showFields && <select {...register("performance", { required: true })} className="tn-input" id="performance">
                  <option value="Live">Live</option>
                  <option value="Streaming">Streaming</option>
                  <option value="Appearance">Appearance</option>
                </select>}
                {!showFields &&
                  <select {...register("composition", { required: true })} className="tn-input" id="composition">
                    <option value="Television">Television</option>
                    <option value="Film">Film</option>
                    <option value="Other">Other</option>
                  </select>}
              </div>
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="needing">
                Needing
              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative">
                <select {...register("needing", { required: true })} className="tn-input" id="needing">
                  <option value="Quote">Quote</option>
                  <option value="Interview Request">Interview Request</option>
                </select>
              </div>
            </div>
          </div>
          {showFields && <>
            <div className="md:flex md:items-center mb-8">
              <div className="md:w-1/3">
                <label className="tn-label" htmlFor="venue-address">
                  Location Address
                </label>
              </div>
              <div className="md:w-2/3 relative">
                <textarea {...register("venueAddress", { required: true })} className="tn-input" id="venue-address" />
                {errors.venueAddress && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
              </div>
            </div>
            <div className="md:flex md:items-center mb-8">
              <div className="md:w-1/3">
                <label className="tn-label" htmlFor="eventSpace">
                  Event Space
                </label>
              </div>
              <div className="md:w-2/3">
                <div className="relative">
                  <select {...register("eventSpace", { required: true })} className="tn-input" id="eventSpace">
                    <option value="Indoors">Indoors</option>
                    <option value="Outdoors">Outdoors</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:flex md:items-center mb-8">
              <div className="md:w-1/3">
                <label className="tn-label" htmlFor="duration">
                  Duration
                </label>
              </div>
              <div className="md:w-2/3 relative">
                <input {...register("duration", { required: true })} className="tn-input" id="duration" />
                {errors.duration && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
              </div>
            </div>
            <div className="md:flex md:items-center mb-8">
              <div className="md:w-1/3">
                <label className="tn-label">
                  Date and Time
                </label>
              </div>
              <div className="md:w-2/3 relative">
                <Datetime
                  value={dateAndTime}
                  onChange={(date) => setStartDate(date)}
                />
                {!dateAndTime && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
              </div>
            </div>
            <div className="md:flex md:items-center mb-8">
              <div className="md:w-1/3">
                <label className="tn-label" htmlFor="sponsored-event">
                  Sponsored Event
                </label>
              </div>
              <div className="md:w-2/3 relative">
                <input {...register("sponsoredEvent", { required: true })} className="tn-input" id="sponsored-event" />
                {errors.sponsoredEvent && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
              </div>
            </div>
          </>}
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="company">
                Company Name and Registration
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("company", { required: true })} className="tn-input" id="company" />
              {errors.company && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="address">
                Company Physical Address
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <textarea {...register("address", { required: true })} className="tn-input" id="address" />
              {errors.address && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="postal-code">
                Postal Code
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("postalCode", { required: true })} className="tn-input" id="postal-code" />
              {errors.postalCode && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="country">
                Country
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("country", { required: true })} className="tn-input" id="country" />
              {errors.country && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="title">
                Title
              </label>
            </div>
            <div className="md:w-2/3">
              <div className="relative">
                <select {...register("title", { required: true })} className="tn-input" id="title">
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </div>
          {showOther && <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="other">
                
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("otherTitle", { required: true })} className="tn-input" id="other" />
              {errors.otherTitle && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          }
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="name">
                Full Name
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("name", { required: true })} className="tn-input" id="name" />
              {errors.name && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="business-phone">
                Business Phone
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("businessPhone", { required: true })} className="tn-input" id="business-phone" />
              {errors.businessPhone && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="your-phone">
                Your Phone
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("yourPhone", { required: true, pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ })} className="tn-input" id="your-phone" />
              {errors.yourPhone?.type === "required" && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
              {errors.yourPhone?.type === "pattern" && <p className="text-red-500 text-xs italic pt-1 absolute">Please enter a valid phone number.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-8">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="your-email">
                Your Email
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <input {...register("yourEmail", {
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })} className="tn-input" id="your-email" />
              {errors.yourEmail?.type === "required" && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
              {errors.yourEmail?.type === "pattern" && <p className="text-red-500 text-xs italic pt-1 absolute">Please enter a valid email address</p>}
            </div>
          </div>
          <div className="md:flex md:items-center mb-10">
            <div className="md:w-1/3">
              <label className="tn-label" htmlFor="description">
                Event Description
              </label>
            </div>
            <div className="md:w-2/3 relative">
              <textarea {...register("description", { required: true })} className="tn-input" id="description" />
              {errors.description && <p className="text-red-500 text-xs italic pt-1 absolute">Please fill out this field.</p>}
            </div>
          </div>
          <div className="md:flex md:items-center pt-2">
            <div className="md:w-1/3"></div>
            <div className="w-full md:w-2/3">
              <button className="w-full md:w-auto shadow bg-yellow-400 hover:bg-yellow-200 focus:shadow-outline focus:outline-none text-black font-bold py-3 px-10 rounded" type="submit">
                Send
              </button>
            </div>
          </div>
        </form>
        <p className="text-xs mt-10 mx-auto text-center text-white">For general enquiries contact us on info@ndlelamusic.co.za</p>
      </section>}
    </main>
  </>
  )
}

export default Booking;