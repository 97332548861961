const Footer = () => {
	return <footer className="bg-gray-900 py-5 px-4 md:px-10 mt-auto">
		<div className="mx-auto">
			<div className="flex flex-col md:flex-row-reverse md:justify-between text-xs md:text-sm md:tracking-wide text-gray-500">
        <div className="my-5 flex justify-center">
              <a className="social-account" href="https://www.instagram.com/ndlelamusic/" title="Thandi Ntuli on Instagram" target="_BLANK" rel="noreferrer">
                <img src="//thandintuli.com/social/icon-instagram.svg" alt="instagram" className="w-8 h-8 mx-4"/>
              </a>
				</div>
				<div className="text-center md:text-left self-center my-5">
					<span>Copyright @ Ndlela Music Company 2021 - All Rights Reserved.</span>
				</div>
				
			</div>
		</div>
	</footer>;
};

export default Footer;